<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    style="width: 100%"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    :default-active-first-option="false"
    v-model="selectedValue"
    :filter-option="filterOption"
    :not-found-content="loading ? undefined : null"
    @focus="getList"
    @change="onChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="(item, index) in option" :value="item.unionCode" :key="index">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
// 通过接口调用获取list
export default {
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    name: {},
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
      loading: false,
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  watch: {
    code() {
      // if (val === this.selectedValue) return
      if (this.option.length > 0) {
        this.setDefault()
      } else if (!this.name) {
        this.getList()
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.option.length > 0) return
      this.loading = true
      let res = await http({
        url: api.getAccountSetList,
        data: {},
      })
      this.loading = false
      let list = res.result.list || res.result || []
      if (list.length === 0) {
        this.$message.warning(this.$t('数据为空'))
      }
      let defaultBu = JSON.parse(localStorage.getItem('defaultBu') || '{}')
      this.option = list
        .filter((elem) => elem.business_unit_code === defaultBu.code)
        .map((item) => {
          item.unionCode = item.code + '-' + item.business_unit_code
          return item
        })
      this.setDefault()
    },
    setDefault() {
      if (this.name) return
      this.selectedValue = undefined
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (this.option.length > 0) {
        this.onChange(this.option[0].unionCode)
      }
    },
    onChange(value) {
      if (value !== undefined) {
        value = String(value)
      }
      this.selectedValue = value
      this.$emit('update:code', value)
      let item = this.option.find((elem) => String(elem.unionCode) === value) || {}
      this.$emit('update:name', item.name)
      this.$emit('change', item)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
